import { useTranslation } from 'react-i18next';

import './section-cta-bar.styles.scss';

function CtaBar({ sectionCtaBarData }) {

  const { url, icon, name } = sectionCtaBarData

  const { t } = useTranslation();

  return (
    <section className="section-cta-bar" aria-label="Icons">
      <div className="content">

        <a href={url} target="_blank" rel="noreferrer">
          <span><i className={icon}></i>{t(name)}</span>
        </a>

      </div>
    </section>
  );
};

export default CtaBar;
