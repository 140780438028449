import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import de from "./de.json"
import en from "./en.json"

i18next.use(initReactI18next).init({
  resources: {
    de: { translation: de },
    en: { translation: en },
  },

  lng: localStorage.getItem("lng") || "de",
});

export default i18next;