import React from 'react';
import { Fragment } from 'react';

import MainNav from '../components/section-main-nav/main-nav.component';
import SectionHero from '../components/section-hero/section-hero.components';
import SectionIntro from '../components/section-intro/section-intro.components';
import SectionSkills from '../components/section-skills/section-skills.components';
import SectionQuote from '../components/section-quote/section-quote.components';
import SectionUsp from '../components/section-usp/section-usp.components';
import SectionClients from '../components/section-clients/section-clients.components';
import SectionProjects from '../components/section-projects/section-projects.components';
import SectionCtaBar from '../components/section-cta-bar/section-cta-bar.component';
import Footer from '../components/section-footer/footer.component';


import PROFILE from '../assets/images/profile.jpg'
import ALDI from '../assets/images/Aldi-Logo.webp'
import ADIDAS from '../assets/images/Adidas-Logo.webp'
import MERCEDES from '../assets/images/Mercedes-Benz-Logo.webp'
import CYNCLY from '../assets/images/cyncly-logo.webp'
import FREUDENBERG from '../assets/images/Freudenberg-Logo.webp'

import ADIDASCORPORATEWEBSITE from '../assets/images/website-adidas-corp.png'
import ADIDASGAMEPLANAWEBSITE from '../assets/images/website-gameplan-a.png'
import ALDIGGWEBSITE from '../assets/images/website-aldi-gaming.png'
import MERCEDESRETTUNGSKARTEN from '../assets/images/website-mercedes-rettungskarten.png'
import MERCEDESEQVLIVESTREAM from '../assets/images/website-mercedes-eqv.png'
import CYNCLYWEBSITE from '../assets/images/website-cyncly.png'
import FPMDRIPSTOPWEBSITE from '../assets/images/website-fpm-dripstop.png'
import FSTSIMMERINGWEBSITE from '../assets/images/website-fst-simmering.png'
import FSTWEBSITE from '../assets/images/website-fst.png'
import FPMFILTURAWEBSITE from '../assets/images/website-fpm-filtura.png'


import '../index.scss';

const App = () => {

  const sectionHeroData = {
    img: {
      src: PROFILE,
      alt: "Profile Picture"
    }
  };

  const sectionIntroData = {
    headline: "Über mich",
    webdev: {
      headline: 'Web Development',
      title: 'Professioneller Frontend Developer',
      copy: 'Als Frontend Webentwickler habe ich an verschiedensten Projekten für die digitale Markenführung gearbeitet und diesen in Teamarbeit und unter Einsatz agiler Vorgehensweisen zum Erfolg verholfen. Durch mehrere Jahre Erfahrung und der Arbeit an Projekten mit unterschiedlichen Größen und Anforderungen, konnte ich mir ein umfangreiches Wissen aneignen. Mein Ziel ist es Dich oder Dein Team bei der Umsetzung digitaler Projekte mit meinen Fähigkeiten zu unterstützen.',
    },
    gamedev: {
      headline: 'Game Development',
      title: 'Game Development Enthusiast',
      copy: 'Neben meiner Haupttätigkeit, der Webentwicklung, habe ich die Spieleentwicklung für mich entdeckt. Dieser widme ich mich mit großer Leidenschaft.',
    },
  };

  const sectionQuoteData = {
    headline: "Bringing ideas to life",
  };

  const sectionClientsData = {
    headline: 'Mit diesen Unternehmen habe ich erfolgreich zusammengearbeitet',
    clients: [
      {
        id: 0,
        company: "ALDI SÜD",
        img: {
          src: ALDI,
          alt: "ALDI SÜD"
        }
      },
      {
        id: 1,
        company: "adidas Group",
        img: {
          src: ADIDAS,
          alt: "adidas Group Logo"
        }
      },
      {
        id: 2,
        company: "Mercedes-Benz",
        img: {
          src: MERCEDES,
          alt: "Mercedes-Benz Logo"
        }
      },
      {
        id: 3,
        company: "CYNCLY",
        img: {
          src: CYNCLY,
          alt: "CYNCLY Logo"
        }
      },
      {
        id: 4,
        company: "Freudenberg",
        img: {
          src: FREUDENBERG,
          alt: "Freudenberg Logo"
        }
      },
    ]
  }

  const sectionProjectsData = {
    headline: 'An diesen Projekten habe ich gearbeitet',
    projects: [
      {
        id: 0,
        img: {
          src: ADIDASCORPORATEWEBSITE,
          alt: "adidas Coporate Website"
        },
        client_headline: "Kunde",
        client: "adidas Group",
        headline: "Projekt",
        project: "adidas Coporate Website",
        period_headline: "Zeitraum",
        period_date: "10.2020 - 12.2020",
        roll_headline: "Rolle",
        roll: [
          { id: 0, name: "Frontend Developer" }
        ],
        description_headline: "Aufgaben",
        description: "Frontend Development - Entwicklung des Website Rebrush.",
        techstack_headline: "Tech stack",
        techstack: [
          { id: 0, name: "HTML" },
          { id: 1, name: "CSS" },
          { id: 2, name: "SCSS" },
          { id: 3, name: "Tailwind.css" },
          { id: 4, name: "JavaScript" },
          { id: 5, name: "Stimulus" },
          { id: 6, name: "GSAP" },
          { id: 7, name: "Cloudinary" },
          { id: 8, name: "Google Analytics" },
          { id: 9, name: "Git" }
        ]
      },
      {
        id: 1,
        img: {
          src: ADIDASGAMEPLANAWEBSITE,
          alt: "adidas Gameplan A Website"
        },
        client_headline: "Kunde",
        client: "adidas Group",
        headline: "Projekt",
        project: "adidas Gameplan A Website",
        period_headline: "Zeitraum",
        period_date: "03.2020 - 06.2021",
        roll_headline: "Rolle",
        roll: [
          { id: 0, name: "Frontend Lead" }
        ],
        description_headline: "Aufgaben",
        description: "Frontend Development - Weiterentwicklung der Gameplan A Website und des Newsletter Marketings.",
        techstack_headline: "Tech stack",
        techstack: [
          { id: 0, name: "HTML" },
          { id: 1, name: "CSS" },
          { id: 2, name: "SCSS" },
          { id: 3, name: "JavaScript" },
          { id: 4, name: "jQuery" },
          { id: 5, name: "T3.js" },
          { id: 6, name: "GSAP" },
          { id: 7, name: "WordPress" },
          { id: 8, name: "PHP" },
          { id: 9, name: "Twig" },
          { id: 10, name: "Cloudinary" },
          { id: 11, name: "Google Analytics" },
          { id: 12, name: "Git" },
          { id: 13, name: "Mailchimp" },
          { id: 14, name: "REST-API" }
        ]
      },
      {
        id: 2,
        img: {
          src: ALDIGGWEBSITE,
          alt: "ALDI GG Website"
        },
        client_headline: "Kunde",
        client: "ALDI SÜD",
        headline: "Projekt",
        project: "ALDI Gaming Website",
        period_headline: "Zeitraum",
        period_date: "03.2021 - 06.2021",
        roll_headline: "Rolle",
        roll: [
          { id: 0, name: "Frontend Lead" }
        ],
        description_headline: "Aufgaben",
        description: "Frontend Development - Entwicklung der ALDI-Gaming Website.",
        techstack_headline: "Tech stack",
        techstack: [
          { id: 0, name: "HTML" },
          { id: 1, name: "CSS" },
          { id: 2, name: "SCSS" },
          { id: 3, name: "Tailwind.css" },
          { id: 4, name: "JavaScript" },
          { id: 5, name: "WordPress" },
          { id: 6, name: "PHP" },
          { id: 7, name: "Git" }
        ]
      },
      {
        id: 3,
        img: {
          src: MERCEDESEQVLIVESTREAM,
          alt: "Mercedes-Benz EQV Website"
        },
        client_headline: "Kunde",
        client: "Mercedes-Benz",
        headline: "Projekt",
        project: "Mercedes-Benz EQV Livestream-Website",
        period_headline: "Zeitraum",
        period_date: "08.2020 - 09.2020",
        roll_headline: "Rolle",
        roll: [
          { id: 0, name: "Frontend Lead" }
        ],
        description_headline: "Aufgaben",
        description: "Frontend Development - Entwicklung der Website für das Mercedes-Benz EQV Livestream Event.",
        techstack_headline: "Tech stack",
        techstack: [
          { id: 0, name: "HTML" },
          { id: 1, name: "CSS" },
          { id: 2, name: "SCSS" },
          { id: 3, name: "Tailwind.css" },
          { id: 4, name: "JavaScript" },
          { id: 5, name: "WordPress" },
          { id: 6, name: "PHP" },
          { id: 7, name: "Git" }
        ]
      },
      {
        id: 4,
        img: {
          src: MERCEDESRETTUNGSKARTEN,
          alt: "Mercedes-Benz Rettungshelfer Website"
        },
        client_headline: "Kunde",
        client: "Mercedes-Benz",
        headline: "Projekt",
        project: "Mercedes-Benz Rettungshelfer Website",
        period_headline: "Zeitraum",
        period_date: "06.2020 - 08.2020",
        roll_headline: "Rolle",
        roll: [
          { id: 0, name: "Frontend Developer" }
        ],
        description_headline: "Aufgaben",
        description: "Frontend Development - Support.",
        techstack_headline: "Tech stack",
        techstack: [
          { id: 0, name: "HTML" },
          { id: 1, name: "CSS" },
          { id: 2, name: "SCSS" },
          { id: 3, name: "JavaScript" },
          { id: 4, name: "Git" }
        ]
      },
      {
        id: 5,
        img: {
          src: CYNCLYWEBSITE,
          alt: "CYNCLY Website"
        },
        client_headline: "Kunde",
        client: "CYNCLY",
        headline: "Projekt",
        project: "CYNCLY Website",
        period_headline: "Zeitraum",
        period_date: "10.2023 - 12.2023",
        roll_headline: "Rolle",
        roll: [
          { id: 0, name: "Frontend Developer" }
        ],
        description_headline: "Aufgaben",
        description: "Frontend Development - Entwicklung des CYNCLY Website Relaunch.",
        techstack_headline: "Tech stack",
        techstack: [
          { id: 0, name: "HTML" },
          { id: 1, name: "CSS" },
          { id: 2, name: "SCSS" },
          { id: 3, name: "Tailwind.css" },
          { id: 4, name: "Alpine.js" },
          { id: 5, name: "Git" },
          { id: 6, name: "TypeScript" },
          { id: 7, name: "Webpack" },
          { id: 8, name: ".NET" },
          { id: 9, name: "Optimizely" },
          { id: 10, name: "Razor-View-Engine" }
        ]
      },
      {
        id: 6,
        img: {
          src: FPMDRIPSTOPWEBSITE,
          alt: "Freudenberg Performance Materials Dripstop Website"
        },
        client_headline: "Kunde",
        client: "Freudenberg",
        headline: "Projekt",
        project: "Freudenberg Performance Materials Dripstop Website",
        period_headline: "Zeitraum",
        period_date: "01.2024 - 02.2024",
        roll_headline: "Rolle",
        roll: [
          { id: 0, name: "Frontend Lead" }
        ],
        description_headline: "Aufgaben",
        description: "Frontend Development - Modernisierung der Frontend-Architektur und Implementierung moderner Technologien.",
        techstack_headline: "Tech stack",
        techstack: [
          { id: 0, name: "HTML" },
          { id: 1, name: "CSS" },
          { id: 2, name: "SCSS" },
          { id: 3, name: "Alpine.js" },
          { id: 4, name: "Webpack" },
          { id: 5, name: ".NET" },
          { id: 6, name: "Optimizely" },
          { id: 7, name: "Razor-View-Engine" },
          { id: 8, name: "Git" }
        ]
      },
      {
        id: 7,
        img: {
          src: FSTSIMMERINGWEBSITE,
          alt: "Freudenberg Sealing Technologie Simmering Website"
        },
        client_headline: "Kunde",
        client: "Freudenberg",
        headline: "Projekt",
        project: "Freudenberg Sealing Technologie Simmering Website",
        period_headline: "Zeitraum",
        period_date: "04.2024 - 04.2024",
        roll_headline: "Rolle",
        roll: [
          { id: 0, name: "Frontend Lead" }
        ],
        description_headline: "Aufgaben",
        description: "Frontend Development - Projektaufbereitung und Rekonstruktion des Projekts aus älteren Dateien.",
        techstack_headline: "Tech stack",
        techstack: [
          { id: 0, name: "HTML" },
          { id: 1, name: "CSS" },
          { id: 2, name: "SCSS" },
          { id: 5, name: "JavaScript" },
          { id: 9, name: "Git" }
        ]
      },
      {
        id: 8,
        img: {
          src: FPMFILTURAWEBSITE,
          alt: "Freudenberg Performance Materials Filtura Website"
        },
        client_headline: "Kunde",
        client: "Freudenberg",
        headline: "Projekt",
        project: "Freudenberg Performance Materials Filtura Website",
        period_headline: "Zeitraum",
        period_date: "06.2024 - 08.2024",
        roll_headline: "Rolle",
        roll: [
          { id: 0, name: "Frontend Lead" }
        ],
        description_headline: "Aufgaben",
        description: "Frontend Development - Frontend Leitung der Entwicklung und Strukturierung der Frontend-Architektur.",
        techstack_headline: "Tech stack",
        techstack: [
          { id: 0, name: "HTML" },
          { id: 1, name: "CSS" },
          { id: 2, name: "SCSS" },
          { id: 3, name: "Tailwind.css" },
          { id: 4, name: "TypeScript" },
          { id: 5, name: "Alpine.js" },
          { id: 6, name: "Webpack" },
          { id: 7, name: ".NET" },
          { id: 8, name: "Optimizely" },
          { id: 9, name: "Razor-View-Engine" },
          { id: 10, name: "Git" }
        ]
      },
      {
        id: 9,
        img: {
          src: FSTWEBSITE,
          alt: "Freudenberg Sealing Technologies Website"
        },
        client_headline: "Kunde",
        client: "Freudenberg",
        headline: "Projekt",
        project: "Freudenberg Sealing Technologies Website",
        period_headline: "Zeitraum",
        period_date: "04.2024 - 06.2024",
        roll_headline: "Rolle",
        roll: [
          { id: 0, name: "Frontend Developer" }
        ],
        description_headline: "Aufgaben",
        description: "Frontend Development - Weiterentwicklung der Plattform sowie Betreuung und Instandhaltung der Codebasis.",
        techstack_headline: "Tech stack",
        techstack: [
          { id: 0, name: "HTML" },
          { id: 1, name: "CSS" },
          { id: 2, name: "SCSS" },
          { id: 3, name: "Tailwind.css" },
          { id: 4, name: "TypeScript" },
          { id: 5, name: "Alpine.js" },
          { id: 6, name: "Webpack" },
          { id: 7, name: ".NET" },
          { id: 8, name: "SiteCore" },
          { id: 9, name: "Razor-View-Engine" }
        ]
      },
    ]
  }

  const sectionSkillsData = {
    headline: 'Meine Fähigkeiten im Überblick',
    leftskill_headline: "Web Development",
    rightskill_headline: "Game Development",
    leftskills: [
      {
        id: 1,
        name: 'React.js',
      },
      {
        id: 2,
        name: 'JavaScript',
      },
      {
        id: 3,
        name: 'WordPress',
      },
      {
        id: 4,
        name: 'TypeScript',
      },
      {
        id: 5,
        name: 'HTML',
      },
      {
        id: 6,
        name: 'CSS',
      },
      {
        id: 7,
        name: 'SCSS',
      },
      {
        id: 8,
        name: 'SASS',
      },
      {
        id: 9,
        name: 'Vue.js',
      },
      {
        id: 10,
        name: 'PHP',
      },
      {
        id: 11,
        name: 'Git',
      },
      {
        id: 12,
        name: 'Alpine.js',
      },
      {
        id: 13,
        name: 'Webpack',
      },
      {
        id: 14,
        name: 'Tailwind.css',
      },
      {
        id: 15,
        name: 'Razor-View-Engine',
      },
      {
        id: 16,
        name: 'GSAP',
      },
    ],
    rightskills: [
      {
        id: 1,
        name: 'Unreal Engine 5',
      },
      {
        id: 2,
        name: 'Blender 3D',
      },
      {
        id: 3,
        name: 'Quixel Mixer',
      },
      {
        id: 4,
        name: 'Marmoset Toolbag 5',
      },
      {
        id: 5,
        name: 'C++',
      }
    ]
  };

  const sectionUspData = {
    id: 0,
    headline: "Warum ich?",
    copy: "Mit meiner mehrjährigen Erfahrung und der Arbeit an Projekten unterschiedlicher Größen und Anforderungen habe ich mir umfangreiche Kenntnisse in verschiedenen Bereichen angeeignet.",
    usp: [
      {
        id: 0,
        icon: "fa-solid fa-code",
        description: "Als Frontend-Entwickler ist es mir wichtig, technisch nachhaltige Projekte zu realisieren, die langfristig den Anforderungen moderner Webanwendungen gerecht werden. Darüber hinaus engagiere ich mich aktiv über mein Gewerk hinaus, indem ich sinnvolle Vorschläge einbringe, die den gesamten Entwicklungsprozess optimieren.",
      },
      {
        id: 1,
        icon: "fa-solid fa-photo-film",
        description: "Ich liebe es, Design und Technik zu kombinieren, um benutzerfreundliche und responsive Anwendungen zu schaffen, die sowohl funktional als auch ästhetisch ansprechend sind. Dazu zählt für mich auch die einfache Bearbeitung von Bildern und Designs.",
      },
      {
        id: 2,
        icon: "fa-solid fa-business-time",
        description: "Durch die regelmäßige Leitung von Technikmeetings und die Verantwortung für das Frontend in verschiedenen Projekten habe ich gelernt, meine Zeit und die meiner Kollegen effektiv zu nutzen. Dabei lege ich großen Wert auf offene und transparente Kommunikation.",
      },
    ]
  }

  const sectionCtaBarData = {
    url: 'mailto:hello@andreas-glosner.de',
    name: 'Kontakt aufnehmen',
    icon: 'fa-solid fa-envelope',
  };

  return (
    <Fragment>
      <MainNav></MainNav>
      <SectionHero sectionHeroData={sectionHeroData} />
      <SectionIntro sectionIntroData={sectionIntroData} />
      <SectionClients sectionClientsData={sectionClientsData} />
      <SectionSkills sectionSkillsData={sectionSkillsData} />
      <SectionQuote sectionQuoteData={sectionQuoteData} />
      <SectionProjects sectionProjectsData={sectionProjectsData} />
      <SectionUsp sectionUspData={sectionUspData} />
      <SectionCtaBar sectionCtaBarData={sectionCtaBarData} />
      <Footer />
    </Fragment>
  )
}

export default App;