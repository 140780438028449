import React from 'react';
import './section-hero.styles.scss';

function SectionHero({ sectionHeroData }) {

  const { img } = sectionHeroData;

  return (
    <section className="section-hero" aria-label="Andreas Glosner Hero Image">
      <img src={img.src} alt={img.alt} fetchpriority="high"></img>
      <div className="headline">
        <h1>Andreas Glosner</h1>
        <h2>Developer</h2>
      </div>
    </section>
  );
};

export default SectionHero;