import React, { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useTranslation } from 'react-i18next';
import Image from '../comp-image/image.component';

import './section-clients.styles.scss';

gsap.registerPlugin(ScrollTrigger);

function SectionClients({ sectionClientsData }) {

  const { headline, clients } = sectionClientsData;

  const { t } = useTranslation();

  const comp = useRef();
  const cardRefs = useRef([]);
  cardRefs.current = [];

  const addToCardRefs = (el) => {
    if (el && !cardRefs.current.push(el));
  }

  useLayoutEffect(() => {

    let mm = gsap.matchMedia();

    mm.add("(min-width: 992px)", () => {

      gsap.from(cardRefs.current, {
        duration: 0.6,
        delay: 0.2,
        stagger: 0.15,
        x: 400,
        ease: "power3.in",
        scrollTrigger: {
          trigger: comp.current,
          start: 'top 80%',
          toggleActions: 'play none none none',
        },
      });


    }, comp);

    return () => mm.revert();

  }, []);

  return (
    <section className="grid section-clients comp-spacing" aria-label="Referenzen" ref={comp}>
        <div className="content">
          <h3 className="headline">{t(headline)}</h3>
          <div className="card-wrapper">
            {clients.map((data) => (
              <div className="card" key={data.id} ref={addToCardRefs}>
                <Image imgData={data.img}></Image>
                <p className="card-body">{data.company}</p>
              </div>

            ))}
          </div>
        </div>
    </section>
  );
};

export default SectionClients;

