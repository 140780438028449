import ModalHandler from '../comp-modal/modal-handler.component';

import logo_white from '../../assets/images/ag_logo_white.png';
import './footer.styles.scss';

function Footer() {

  const currentYear = new Date().getFullYear();

  return (
    <footer className="grid footer" style={{ "marginBottom": "0px" }} aria-label="Footer">
      <div className="cols {">
        <div className="footer-wrapper">
          <div className="content">

            <div className="logo-wrapper">
              <img src={logo_white} alt="logo" width="100px" height="100px"></img>
            </div>

            <div className="nav-wrapper">
              <nav className="footer-nav">
                <ModalHandler modalContent={"Imprint"} modalTitle="Impressum" />
                <div className="seperator"></div>
                <ModalHandler modalContent={"Privacy"} modalTitle="Datenschutz" />
              </nav>
              <p className="copyright">Made by Andreas Glosner &#169; {currentYear}</p>
            </div>
          </div>

        </div>
      </div>
    </footer >
  );
};

export default Footer;