import React, { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useTranslation } from 'react-i18next';
import './section-usp.styles.scss';

gsap.registerPlugin(ScrollTrigger);

function SectionUsp({ sectionUspData }) {

  const { headline, copy, usp } = sectionUspData;


  const { t } = useTranslation();

  const comp = useRef();
  const cardRefs = useRef([]);
  cardRefs.current = [];

  const addToUspRefs = (el) => {
    if (el && !cardRefs.current.push(el));
  }

  useLayoutEffect(() => {

    let mm = gsap.matchMedia()

    mm.add("(min-width: 992px)", () => {

      gsap.from(cardRefs.current, {
        duration: 0.6,
        opacity: 0,
        y: 100,
        delay: 0.2,
        stagger: 0.15,
        ease: "power3.in",
        scrollTrigger: {
          trigger: comp.current,
          start: 'top 80%',
          toggleActions: 'play none none none',
        },
      });


    }, comp);

    return () => mm.revert();

  }, []);

  return (
    <section className="grid section-usp comp-spacing" aria-label="Fähigkeiten" ref={comp}>
      <div className="cols usp-wrapper">
        <h3 className="headline">{t(headline)}</h3>
        <p className="copy">{t(copy)}</p>
        <div className="usp-item-wrapper">
          {usp.map((usp) => (
            <div className="item" key={usp.id} ref={addToUspRefs}>
              <i className={usp.icon}></i>
              <p>{t(usp.description)}</p>
            </div>
          ))}
        </div>
      </div>
    </section >
  );
};

export default SectionUsp;