import React, { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useTranslation } from 'react-i18next';

import './section-skills.styles.scss';

gsap.registerPlugin(ScrollTrigger);

function SectionSkills({ sectionSkillsData }) {

  const { headline, leftskills, rightskills, leftskill_headline, rightskill_headline } = sectionSkillsData;

  const { t } = useTranslation();

  const comp = useRef();

  const leftskillRefs = useRef([]);
  const rightskillRefs = useRef([]);
  leftskillRefs.current = [];
  rightskillRefs.current = [];

  const addToleftSkillRefs = (el) => {
    if (el && !leftskillRefs.current.push(el));
  }

  const addTorightSkillRefs = (el) => {
    if (el && !rightskillRefs.current.push(el));
  }

  useLayoutEffect(() => {

    let mm = gsap.matchMedia()

    mm.add("(min-width: 992px)", () => {
      gsap.from(leftskillRefs.current, {
        duration: 0.1,
        opacity: 0,
        delay: 0.2,
        stagger: .1,
        ease: "power3.in",
        scrollTrigger: {
          trigger: comp.current,
          start: 'top 80%',
          toggleActions: 'play none none none',
        },
      })

      gsap.from(rightskillRefs.current, {
        duration: 0.1,
        opacity: 0,
        delay: 0.2,
        stagger: .35,
        ease: "power3.in",
        scrollTrigger: {
          trigger: comp.current,
          start: 'top 90%', 
          toggleActions: 'play none none none',
        },
      });

    }, comp);

    return () => mm.revert();

  }, []);

  return (
    <section className="grid section-skills comp-spacing" aria-label="Fähigkeiten" ref={comp}>
      <div className="cols">
        <div className="content">

          <h3 className="headline">{t(headline)}</h3>

          <div className="left-skill-wrapper" >
            <h2 className="headline">{leftskill_headline}</h2>
            <div className="leftskills">
              {leftskills.map((skill) => (
                <span className="skilltag" key={skill.id} ref={addToleftSkillRefs}>{skill.name}</span>
              ))}
            </div>
          </div>

          <div className="right-skill-wrapper">
            <h2 className="headline">{rightskill_headline}</h2>
            <div className="rightskills">
              {rightskills.map((skill) => (
                <span className="skilltag" key={skill.id} ref={addTorightSkillRefs}>{t(skill.name)}</span>
              ))}
            </div>
          </div>

        </div>
      </div>
    </section >
  );
};

export default SectionSkills;