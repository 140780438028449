import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Image from '../comp-image/image.component';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, EffectFade, Navigation } from 'swiper/modules';

import './section-projects.styles.scss';
import 'swiper/swiper-bundle.css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation'; // Importiere die Navigation CSS

function SectionProjects({ sectionProjectsData }) {
  const { headline, projects } = sectionProjectsData;
  const { t } = useTranslation();
  const comp = useRef(); // create a ref for the root level element (for scoping)
  const slideRefs = useRef([]);
  const swiperRef = useRef(null); // Create a ref for Swiper instance

  slideRefs.current = [];

  const addToSlideRefs = (el) => {
    if (el && !slideRefs.current.includes(el)) {
      slideRefs.current.push(el);
    }
  };

  const handleMouseEnter = () => {
    swiperRef.current?.autoplay.stop(); // Stop autoplay on hover
  };

  const handleMouseLeave = () => {
    swiperRef.current?.autoplay.start(); // Start autoplay when mouse leaves
  };

  const handleTouchStart = () => {
    swiperRef.current?.autoplay.stop(); // Stop autoplay on touch
  };

  const handleTouchEnd = () => {
    swiperRef.current?.autoplay.start(); // Start autoplay after touch ends
  };

  const settings = {
    modules: [Autoplay, Pagination, EffectFade, Navigation],
    slidesPerView: 1,
    spaceBetween: 0,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    autoplay: {
      delay: 3500,
    },
    loop: true,
    pagination: { clickable: true },
    navigation: { // Navigation aktivieren
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    onSwiper: (swiper) => {
      swiperRef.current = swiper; // Store the Swiper instance in the ref
    }
  };

  return (
    <section className="grid section-projects comp-spacing" aria-label="Referenzen" ref={comp}>
      <h3 className="headline">{t(headline)}</h3>
      <Swiper
        {...settings}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        {projects.map((data) => (
          <SwiperSlide key={data.id}>
            <div className="text-image" ref={addToSlideRefs}>
              <Image imgData={data.img}></Image>
              <div className="text">
                <ul>
                  <li><b>{t("Kunde")}</b><br />
                    {t(data.client)}
                  </li>
                  <li><b>{t("Projekt")}</b><br />
                    {data.project}<br />
                  </li>
                  <li><b>{t("Zeitraum")}</b><br />
                    {t(data.period_date)}<br />
                  </li>
                  <li><b>{t("Rolle")}</b><br />
                    {data.roll.map((roll) => (
                      <span key={roll.id}>{roll.name}<br /></span>
                    ))}
                  </li>
                  <li><b>{t("Aufgaben")}</b><br />
                    {t(data.description)}
                  </li>
                  <li><b>{"Tech stack"}</b><br />
                    <div className="techstack-wrapper">
                      {data.techstack.map((tech) => (
                        <span className="skilltag" key={tech.id}>{tech.name}</span>
                      ))}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </SwiperSlide>
        ))}

        <div className="swiper-button-prev"></div>
        <div className="swiper-button-next"></div>
      </Swiper>
    </section>
  );
};

export default SectionProjects;
