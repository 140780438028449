import React, { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useTranslation } from 'react-i18next';
import './section-quote.styles.scss';

gsap.registerPlugin(ScrollTrigger);

function SectionProducts({ sectionQuoteData }) {

  const { headline } = sectionQuoteData;


  const { t } = useTranslation();

  const comp = useRef();
  const cardRefs = useRef([]);
  cardRefs.current = [];

  const addToProductsRefs = (el) => {
    if (el && !cardRefs.current.push(el));
  }

  useLayoutEffect(() => {

    let mm = gsap.matchMedia()

    mm.add("(min-width: 992px)", () => {

      gsap.from(cardRefs.current, {
        duration: 0.6,
        opacity: 0,
        y: 50,
        delay: 0.3,
        stagger: 0.1,
        ease: "power2.in",
        scrollTrigger: {
          trigger: comp.current,
          start: 'top 80%',
          toggleActions: 'play none none none',
        },
      });


    }, comp);

    return () => mm.revert();

  }, []);

  return (
    <section className="grid section-quote comp-spacing" aria-label="Fähigkeiten" ref={comp}>
        <blockquote className="cols headline" ref={addToProductsRefs}>{t(headline)}</blockquote>
    </section >
  );
};

export default SectionProducts;