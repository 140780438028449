function Image({ imgData }) {

  const { src, alt } = imgData

  return (
    <picture>
      {/* 
      <source media="(max-width: 799px)" srcset="puppy-480w-cropped.jpg" />
      <source media="(min-width: 800px)" srcset="puppy-800w.jpg" />
      <img src="puppy-800w.jpg" alt="Puppy with balloons" /> 
      */}
      <img src={src} alt={alt} loading="lazy"></img>
    </picture>
  );
};

export default Image;
